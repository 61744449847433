.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.one {
  background-color:rgb(21, 20, 20);
  padding: 5%;
  /* max-width: 1140px; */
}

.section > * {
  text-align: center;
}